import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './LatestPosts.module.css';

// Importaciones de Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import './swiper.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const LatestPosts = ({ showSection }) => {
  const [posts, setPosts] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const [activeIndex, setActiveIndex] = useState(0); // Estado para el bullet activo
  const totalBullets = 3; // Cambia esto según el número de bullets que necesites

  useEffect(() => {
    // Obtener los posts del blog
    axios
      .get('https://blog.eno.network/wp-json/wp/v2/posts?per_page=3&_embed')
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching the posts:', error);
      });

    // Event listener para actualizar el tamaño de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024); // Ajusta el ancho máximo para incluir iPad
    };

    // Agregar el event listener
    window.addEventListener('resize', handleResize);

    // Limpiar el event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const createExcerpt = (htmlContent, maxLength) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    return textContent.length > maxLength ? textContent.slice(0, maxLength) + '...' : textContent;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className={styles.latestNews}>
      {isMobileView ? (
        <>
          {showSection === 'lastpost-1' && (
            <div className={`${styles.latestContainer} ${styles.latestContainerOne}`}>
              <div className={styles.headerContainerOne}>
                <h2>Latest News</h2>
                <a href="https://blog.enotoken.io/" className={styles.viewAllBlogsBtnOne}>
                  View all blogs
                </a>
              </div>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                className={`${styles.postsSwiper} ${styles.postsSwiperOne}`}
                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                }}
              >
                {posts.map((post) => (
                  <SwiperSlide key={post.id} className={`${styles.postSlide} ${styles.postSlideOne}`}>
                    <div className={`${styles.post} ${styles.postOne}`}>
                      <img
                        src={post._embedded['wp:featuredmedia'][0]?.source_url || 'default-image-url.png'}
                        alt={post.title.rendered}
                        className={`${styles.postImage} ${styles.postImageOne}`}
                      />
                      <p className={styles.postDate}>{formatDate(post.date)}</p>
                      <h2 className={styles.postTitle}>{post.title.rendered}</h2>
                      <div className={styles.postExcerpt}>
                        {createExcerpt(post.excerpt.rendered, 100)}
                      </div>
                      <div className={styles.btnContainer}>
                        <a
                          href={post.link}
                          className={`${styles.heroBtn} ${styles.heroBtnColor}`}
                          rel="noopener noreferrer"
                        >
                          Read Now
                        </a>
                      </div>
                      <div className={styles.BlogsContainer}>

                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          {showSection === 'lastpost-2' && (
            <div className={`${styles.latestContainer} ${styles.latestContainerTwo}`}>
              <div className={styles.headerContainer}>
                <h2>Latest News</h2>
                <a href="https://blog.enotoken.io/" className={styles.viewAllBlogsBtn}>
                  View all blogs
                </a>
              </div>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                className={`${styles.postsSwiper} ${styles.postsSwiperTwo}`}
                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                }}
              >
                {posts.map((post) => (
                  <SwiperSlide key={post.id} className={`${styles.postSlide} ${styles.postSlideTwo}`}>
                    <div className={`${styles.post} ${styles.postTwo}`}>
                      <img
                        src={post._embedded['wp:featuredmedia'][0]?.source_url || 'default-image-url.png'}
                        alt={post.title.rendered}
                        className={`${styles.postImage} ${styles.postImageTwo}`}
                      />
                      <p className={styles.postDate}>{formatDate(post.date)}</p>
                      <h2 className={styles.postTitle}>{post.title.rendered}</h2>
                      <div className={styles.postExcerpt}>
                        {createExcerpt(post.excerpt.rendered, 100)}
                      </div>
                      <div className={styles.btnContainer}>
                        <a
                          href={post.link}
                          className={`${styles.heroBtn} ${styles.heroBtnColor}`}
                          rel="noopener noreferrer"
                        >
                          Read Now
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </>
      ) : (
        <div className={styles.latestContainer}>
          <div className={styles.headerContainer}>
            <h2>Latest News</h2>
            <a href="https://blog.enotoken.io/" className={styles.viewAllBlogsBtn}>
              View all blogs
            </a>
          </div>
          <div className={styles.postsContainer}>
            {posts.map((post) => (
              <div key={post.id} className={styles.post}>
                <img
                  src={post._embedded['wp:featuredmedia'][0]?.source_url || 'default-image-url.png'}
                  alt={post.title.rendered}
                  className={styles.postImage}
                />
                <p className={styles.postDate}>{formatDate(post.date)}</p>
                <h2 className={styles.postTitle}>{post.title.rendered}</h2>
                <div className={styles.postExcerpt}>
                  {createExcerpt(post.excerpt.rendered, 100)}
                </div>
                <div className={styles.btnContainer}>
                  <a
                    href={post.link}
                    className={`${styles.heroBtn} ${styles.heroBtnColor}`}
                    rel="noopener noreferrer"
                  >
                    Read Now
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.bulletContainer}>
        {Array.from({ length: totalBullets }).map((_, index) => (
          <div
            key={index}
            className={`${styles.bullet} ${index === activeIndex ? styles.active : ''}`}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default LatestPosts;