import React, { useEffect } from "react";
/* import { LazyLoadImage } from "react-lazy-load-image-component"; */
import "react-lazy-load-image-component/src/effects/blur.css";
import LazyLoad from "react-lazyload";
//Estilos
import "./ContentSystem.css";

// Imágenes
import enologo from "./assets/img/ENOLogoHeader.webp";
import coinMarketCap from "./assets/img/CoonMarketCap.webp"
import warpCast from "./assets/img/WarpCast.webp";
import coinGecko from "./assets/img/Coingecko.webp"

//Eno trade
import uniswap from "./assets/img/Trade/Uniswap.webp";
import bitmart from "./assets/img/Trade/Bitmart.webp";
import latoken from "./assets/img/Trade/latoken.webp";
import coingeckro from "./assets/img/Trade/Coingeckologo.webp";
import coinmarket from "./assets/img/Trade/Coinmarketcap.webp";
import Trubit from "./assets/img/Trade/Trubit.webp";
import Zealy from "./assets/img/Zealy.webp";

//Media
import benzinga from "./assets/img/Media/Benxinga.png";
import forbes from "./assets/img/Media/Forbes.png";
import investing from "./assets/img/Media/Investing.png";
import marketwatch from "./assets/img/Media/MarketWatch.png";
import yahoo from "./assets/img/Media/YaHoo.png";
import coinmarketcap from "./assets/img/Media/CMC.png";
import newsbtc from "./assets/img/Media/NewsBTC.png";
import cryptodaily from "./assets/img/Media/CryptoDaily.png";

// Videos
import SocialClub from "./Videos/Utilities.mp4";
import EnoVerse from "./Videos/EnoVerse.mp4";
import Exchanges from "./Videos/Swap.mp4";

//Agnes
import educator from "./Videos/Educator.mp4";
import assistantGPT from "./Videos/Assistant.mp4";
import ambassador from "./Videos/Ambassador.mp4";
import vortex from "./Videos/Vortex.mp4";
import InsentivesRewards from "./Videos/InsentivesRewards.mp4";
import stakingvideo from "./Videos/Staking.mp4";
import membershipEWSC from "./Videos/membresiaEWSC.mp4";
import AgnesAmbassador from "./Videos/AgnesAmbassador.mp4";
import DigitalAssets from "./Videos/DigitalAssets.mp4";
import Labs from "./Videos/Labs.mp4";
import castleNew from "./Videos/SphereCity.mp4";
import EnoNFT from "./Videos/EnoNFTs.mp4";

//Core Team
import albertoFernandez from "./assets/img/Core team/Alberto.webp";
import davidMarquez from "./assets/img/Core team/David.webp";
import antonioOrnelas from "./assets/img/Core team/Antuan.webp";
import angelArzate from "./assets/img/Core team/Antonio.webp";
import JuanDeLaRosa from "./assets/img/Core team/Juanjose.webp";
import PaolaGarrido from "./assets/img/Core team/Paola.webp";
import tokenomics from "./assets/img/Tokenomics.png";

//Componentes
import LastestPost from "./Templates/LatestPosts";
import Navbar from "./components/NavBar";
import Carousel from "./components/Carousel";
import LazyVideo from "./components/LazyVideo";
import BackToTopButton from "./components/topButton";
import BadgesNewsletter from "./components/BadgesNewsletter";
import ProgressiveLoadingSection from "./components/ProgressiveLoadingSection";
import OptimizedImage from "./components/OptimizedImage";

/* ============ FONT A W E S O M E ============ */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faXTwitter,
  faInstagram,
  faDiscord,
  faTelegram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const App = () => {
  useEffect(() => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      video.muted = true;
      video.playsInline = true;
      video.play().catch((error) => {
        console.log("Error playing video:", error);
      });
    });
  }, []);

  return (
    <div className="app__container">
      {/* ======= Navbar ======= */}
      <Navbar />
      {/* ======= Hero ======= */}
      <section className="hero__container">
        <div className="hero__content">
          <div className="hero__left">
            {/* <OptimizedImage src={enologo} alt="Logo" className="hero__logo" /> */}
            {/* Iconos Sociales */}
            <div className="global__icons">
              <div className="social__container">
                <div className="social__icons__container">
                  <div className="social__icons icon__list">
                    <a
                      href="https://t.me/ENO_network"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a
                      href="https://discord.com/invite/3eZewHzJU5"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a
                      href="https://x.com/ENOnetwork"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/enonetwork"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a
                      href="https://www.youtube.com/@eno.network"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a
                      href="https://www.instagram.com/enonetwork/"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a
                      href="https://www.facebook.com/ENOtoken"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://warpcast.com/enotoken" rel="noopener noreferrer">
                      <OptimizedImage className="warpIcon" src={warpCast} />
                    </a>

                    <a href="https://coinmarketcap.com/community/profile/EnoToken" rel="noopener noreferrer">
                      <OptimizedImage className="MarketIcon" src={coinMarketCap} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="hero__title">
              one network,
              infinite
              possibilities
            </h2>
            <span className="span__hero">
              All in one place to create and explore, both digitally and in the real world: NFT launchpad, marketplace, and metaverse, hosted by AI services.
            </span>
            <div className="hero__btn__container">
              <a
                href="https://docs.enotoken.io/"
                rel="noopener noreferrer"
                className="hero__btn color-1"
              >
                Whitepaper
              </a>

              <a
                href="https://app.eno.network/"
                rel="noopener noreferrer"
                className="hero__btn btn-alternativeColor"
              >
                Launch App
              </a>
            </div>
            <br />

            {/* Iconos Sociales */}
            <div className="hero__icon__container">
              <div className="hero__icons__box">
                <div className="hero__boxes">
                  <a
                    href="https://t.me/ENO_network"
                    rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegram} />
                  </a>
                  <a
                    href="https://discord.com/invite/3eZewHzJU5"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faDiscord} />
                  </a>
                  <a
                    href="https://x.com/ENOnetwork"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faXTwitter} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/enonetwork"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                  <a
                    href="https://www.youtube.com/@eno.network"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a
                    href="https://www.instagram.com/enonetwork/"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    href="https://www.facebook.com/ENOtoken"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href="https://warpcast.com/enotoken" rel="noopener noreferrer">
                    <OptimizedImage className="warpIcon" src={warpCast} />
                  </a>

                  <a href="https://coinmarketcap.com/community/profile/EnoToken" rel="noopener noreferrer">
                    <OptimizedImage className="MarketIcon" src={coinMarketCap} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="hero__right">
            <div class="backSphereCity">
              <LazyVideo src={castleNew} className="personalizado" />
            </div>
          </div>
        </div>
      </section>
      {/* ======= Logo EWSC ======= */}
      {/* <section className="ewsc__container">
            <h2 className="section__title">Our First Social Club</h2>
            <div className="ewsc__logo">
               <a
                  href="https://enowinesocialclub.io/"
                   
                  rel="noopener noreferrer"
               >
                  <OptimizedImage src={ewsc} alt="whitepaper" />
               </a>
            </div>
      </section> */}
      {/* ======= ENO BLOCKCHAIN TOOL ======= */}
      <section className="token__utility__container__agnes" id="utility">
        <div className="blockchain__titles">
          <div className="whyContent">
            <h2 className="hero__title-alt">Why All In One?</h2>
            <p className="join__span">
              Giving creators new possibilities, we enable ideas to come to life in the metaverse by offering tools to customize NFTs. Our ecosystem encourages creativity and provides projects with a space to design and connect with diverse communities.
            </p>
          </div>

          <h3 className="section__title-alt">We do this through the</h3>
          <h2 className="hero__title-alt">ENO ECOSYSTEM</h2>
        </div>
        <p className="join__span-alt"></p>
        <div className="token__utility__content">
          <div className="token__utility__box">
            <div className="token__boxes">
              <div class="backGorro">
                <LazyVideo src={DigitalAssets} className="personalizado" />
              </div>
              <p className="token__label-alt">All-in-One <br /> Ecosystem</p>
              <p className="join__subtitle2">
                ENO combines Launchpad, NFT marketplace, metaverse, and AI services in one platform for a seamless experience.
              </p>
            </div>
            <div className="token__boxes">
              <div class="backGorro">
                <LazyVideo src={Labs} className="personalizado" />
              </div>
              <p className="token__label-alt">Community-Driven Growth</p>
              <p className="join__subtitle2">
                ENO Clubs support tools for user engagement and loyalty through both digital and real-world activities.
              </p>
            </div>
            <div className="token__boxes">
              <div class="backGorro">
                <LazyVideo src={ambassador} className="personalizado" />
              </div>
              <p className="token__label-alt">Agnes AI - Personalized User Experience</p>
              <p className="join__subtitle2">
                Agnes AI provides personalized insights and support, simplifying Web3 for all users.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Badges ======= */}
      <BadgesNewsletter />

      {/* ======= Video Container EWSC  =======* */}
      < section className="video__container" >
        <div className="video__content">
          <iframe
            width="100%"
            height="720px"
            src="https://www.youtube-nocookie.com/embed/x0DtMPHBJUg"
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay; encrypted-media; picture-in-picture"
            className="youtube-video"
          ></iframe>
        </div>
      </section >
      {/* ======= Agnes Section ======= */}
      < section className="token__utility__container__agnes" id="utility" >
        <h2 className="section__title">
          MEET AGNES: AN ALL-IN-ONE HOST
        </h2>
        <p className="join__span">
          Agnes, our AI, provides all the information needed to fully utilize the ENO platform while continuing her mission of helping humans remember to be kind to one another.
        </p>
        <div className="token__utility__content">
          <div className="token__utility__box">
            <div className="token__boxes">
              <div class="backGorro">
                <LazyVideo src={educator} className="personalizado" />
              </div>
              <p className="token__label">Virtual Teacher</p>
              <p className="join__subtitle2">
                This capability enables us to provide personalized learning
                journeys at ENO academy.
              </p>
            </div>
            <div className="token__boxes">
              <div class="backAssistant">
                <LazyVideo src={assistantGPT} className="personalizado" />
              </div>
              <p className="token__label">Virtual Assistant</p>
              <p className="join__subtitle2">
                Agnes assists users with daily interactions on the platform,
                managing FAQs and providing real-time support.
              </p>
            </div>
            <div className="token__boxes">
              <div class="backAgnes">
                <LazyVideo src={AgnesAmbassador} className="personalizado" />
              </div>
              <p className="token__label">Ambassador</p>
              <p className="join__subtitle2">
                Agnes acts as a representative and promoter of the whole ENO
                ecosystem.
              </p>
            </div>
          </div>
        </div>
      </section >

      {/* ======= Token Utility ======= */}
      < section className="token__utility__container" id="utility" >
        <h2 className="section__title">ENO Utility</h2>
        <div className="token__utility__content">

          {/* Up Site */}
          <div className="token__utility__box">
            {/* NFT's */}
            <div className="token__boxes">
              <a
                href="https://docs.enotoken.io/eno-digital-assets"
                rel="noopener noreferrer"
                className="backGorro"
              >
                <LazyVideo src={EnoNFT} className="personalizado" />
              </a>
              <a
                href="https://docs.enotoken.io/eno-digital-assets"
                rel="noopener noreferrer"
                className="utilityBtn"
              >
                <p className="token__label-alt">NFT</p>
              </a>
              <p className="join__subtitle2">Trade and own ENO and our partners NFTs, to get exclusive perks.</p>
            </div>
            {/* Create Your Social Club */}
            <div className="token__boxes">
              <a
                href="https://docs.enotoken.io/eno-platform/eno-social-clubs"
                rel="noopener noreferrer"
                className="backGorro"
              >
                <LazyVideo src={SocialClub} className="personalizado" />
              </a>
              <a
                href="https://docs.enotoken.io/eno-platform/eno-social-clubs"
                rel="noopener noreferrer"
                className="utilityBtn"
              >
                <p className="token__label-alt">ENO Clubs</p>
              </a>
              <p className="join__subtitle2">Access exclusive events, virtual workshops, digital collectibles.</p>
            </div>
            {/* ENOVerse */}
            <div className="token__boxes">
              <a
                href="https://docs.enotoken.io/enoverse"
                rel="noopener noreferrer"
                className="backGorro"
              >
                <LazyVideo src={EnoVerse} className="personalizado" />
              </a>

              <a
                href="https://docs.enotoken.io/enoverse"
                rel="noopener noreferrer"
                className="utilityBtn"
              >
                <p className="token__label-alt">Metaverse</p>
              </a>
              <p className="join__subtitle2">Explore special experiences in our private ENOVERSE</p>
            </div>
          </div>
          {/* Down Site */}
          <div className="token__utility__box">
            {/* Staking */}
            <div className="token__boxes">
              <a
                href="https://docs.enotoken.io/usdeno-token/usdeno-utility/staking"
                rel="noopener noreferrer"
                className="backGorro"
              >
                <LazyVideo src={stakingvideo} className="personalizado" />
              </a>
              <a
                href="https://docs.enotoken.io/usdeno-token/usdeno-utility/staking"
                rel="noopener noreferrer"
                className="utilityBtn"
              >
                <p className="token__label-alt">Staking</p>
              </a>
              <p className="join__subtitle2">To earn additional tokens.</p>
            </div>
            {/* Incentives and Rewards */}
            <div className="token__boxes">
              <a
                href="https://docs.enotoken.io/usdeno-token/usdeno-utility"
                rel="noopener noreferrer"
                className="backGorro"
              >
                <LazyVideo src={InsentivesRewards} className="personalizado" />
              </a>
              <a
                href="https://docs.enotoken.io/usdeno-token/usdeno-utility"
                rel="noopener noreferrer"
                className="utilityBtn"
              >
                <p className="token__label-alt">Incentives and Rewards</p>
              </a>
              <p className="join__subtitle2">Rewards for contributing to growth of the social clubs.</p>
            </div>
            {/* Exchanges */}
            <div className="token__boxes">
              <a
                href="#trade"
                rel="noopener noreferrer"
                className="backGorro"
              >
                <LazyVideo src={Exchanges} className="personalizado" />
              </a>

              <a
                href="#trade"
                rel="noopener noreferrer"
                className="utilityBtn"
              >
                <p className="token__label-alt">Trade</p>
              </a>
              <p className="join__subtitle2">ENO to smoothly exchange goods and services within the ENO community.</p>
            </div>
          </div>
        </div>
      </section >
      {/* ======= Membership ======= */}
      < section className="membership__video" >
        <div className="membership__contents">
          <div className="membership__left">
            <h4 className="subtitle__membership">Our first success case</h4>
            <h2 className="section__title-membership">ENO WINE SOCIAL CLUB</h2>
            <span className="span__subtitle-membership">
              Leads the future of decentralized social clubs by creating a dynamic community for wine enthusiasts, offering global live experiences and exclusive digital benefits.
            </span>
            <a
              href="https://enowinesocialclub.io/"
              rel="noopener noreferrer"
              className="membership-button"
            >
              <button className="hero__btn color-1">Learn More</button>
            </a>
          </div>
          <div className="membership___right">
            <div className="getMembership">
              <LazyVideo src={membershipEWSC} className="personalizado" />
            </div>
          </div>
        </div>
      </section >
      {/* ======= Membership Mobile======= */}
      < section className="membership__video-modile" >
        <div className="membership__contents">
          <div className="membership__left">
            <h4 className="subtitle__membership">Our first success case</h4>
            <h2 className="section__title-membership">ENO WINE SOCIAL CLUB</h2>
            <div className="membership___right">
              <div className="getMembership">
                <LazyVideo src={membershipEWSC} className="personalizado" />
              </div>
            </div>
            <span className="span__subtitle-membership">
              Leads the future of decentralized social clubs by creating a
              dynamic community for wine enthusiasts, offering global live
              experiences and exclusive digital benefits.
            </span>
            <a
              href="https://enowinesocialclub.io/"
              rel="noopener noreferrer"
              className="membership-button"
            >
              <button className="hero__btn color-1">Learn More</button>
            </a>
          </div>
        </div>
      </section >
      {/* ======= Our Partners - Carrousel ======= */}
      < section className="our__partners__container" id="partners" >
        <h2 className="section__title">Our Partners</h2>
        <Carousel />
        {/* ======= Mapa ======= */}
        {/* <div className="ourPartners-box">
               <div className="ourPartners__left">
                  <h2 className="section__title">ENO Over The World</h2>
                  <p className="ourParnets__text">
                     We have an extensive international collaboration network at ENO. Each illuminated point represents our growing community and the diversity of our strategic alliances. ENO connects different cultures and sectors to foster innovation and web 3.0 technological development on a global scale.
                  </p>
               </div>

               <div className="ourPartners__right">
                  <div className="our__partners__content">
                     <OptimizedImage
                        alt="Mapa"
                        src={mapa}
                        effect="blur"
                        className="mapaimagen"
                     />
                  </div>
               </div>
            </div> */}
      </section >
      {/* ======= Trade Eno Token at ======= */}
      < section className="trade__container" id="trade" >
        <h2 className="section__title">Trade ENO at</h2>
        <div className="trade__content">
          {/* Left */}
          <div className="trade__left">
            <div className="trade__box1">
              <a
                href="https://academy.trubit.com/new-english-trubit-academy/crypto-introduction/eno"
                rel="noopener noreferrer"
              >
                <button className="trade__label">
                  <OptimizedImage
                    alt="TRUBIT"
                    effect="blur"
                    style={{ width: "70%" }}
                    src={Trubit}
                  />
                </button>
              </a>
              <p className="trade__btn">TRUBIT</p>
            </div>
            <div className="trade__box2">
              <a
                href="https://app.uniswap.org/swap?inputCurrency=0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9&outputCurrency=0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F&chain=arbitrum"
                rel="noopener noreferrer"
              >
                <button className="trade__label">
                  <OptimizedImage
                    alt="Uniswap"
                    src={uniswap}
                    effect="blur"
                    style={{ width: "80%" }}
                  />
                </button>
              </a>
              <p className="trade__btn">UNISWAP</p>
            </div>
            <div className="trade__box3">
              <a
                href="https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=ENO_USDT"
                rel="noopener noreferrer"
              >
                <button className="trade__label">
                  <OptimizedImage
                    alt="BITMART"
                    src={bitmart}
                    effect="blur"
                    style={{ width: "50%" }}
                  />
                </button>
              </a>
              <p className="trade__btn">BITMART</p>
            </div>
            <div className="trade__box4">
              <a
                href="https://latoken.com/exchange/ENO_USDT"
                rel="noopener noreferrer"
              >
                <button className="trade__label">
                  <OptimizedImage
                    alt="LATOKEN"
                    src={latoken}
                    effect="blur"
                    style={{ width: "80%" }}
                  />
                </button>
              </a>
              <p className="trade__btn">LATOKEN</p>
            </div>
          </div>
        </div>
      </section >
      {/* ======= Media ======= */}
      < section className="media__container" id="media" >
        <h2 className="section__title">Media</h2>
        <div className="media__content">
          <div className="benzinga">
            <a
              href="https://www.benzinga.com/markets/cryptocurrency/21/11/24146710/eno-token-and-nfts-in-the-wine-industry-to-watch"
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={benzinga}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
          <div className="forbes">
            <a
              href="https://www.forbes.com/sites/digital-assets/2024/04/10/how-rwas-in-web3-are-impacting-consumer-experience/?sh=5faa88ca7a30"
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={forbes}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
          <div className="investing">
            <a
              href="https://www.investing.com/news/cryptocurrency-news/enotoken-airdrop-is-coming-soon-comprehensive-details-2582293"
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={investing}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
        </div>
        <div className="media__content">
          <div className="marketwatch">
            <a
              href="https://markets.businessinsider.com/news/stocks/introducing-eno-token---a-new-crypto-created-for-the-wine-environment-1030621539"
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={marketwatch}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
          <div className="coinmarketcap">
            <a
              href="https://coinmarketcap.com/community/articles/6616a5a404884e11431f1ca9/"
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={coinmarketcap}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
          <div className="yahoo">
            <a
              href="https://finance.yahoo.com/news/eno-strikes-groundbreaking-partnership-mexicos-114000329.html?guccounter=1"
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={yahoo}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
        </div>
        <div className="media__content">
          <div className="newsbtc">
            <a
              href="https://www.newsbtc.com/all/raise-a-glass-to-eno-enos-new-era-of-wine-nfts-and-social-connectivity/"
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={newsbtc}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
          <div className="cryptodaily">
            <a
              href=""
              rel="noopener noreferrer"
            >
              <div className="optimized-image">
                <OptimizedImage
                  src={cryptodaily}
                  alt="benzinga"
                  offset={100}
                  effect="blur"
                />
              </div>
            </a>
          </div>
        </div>
      </section >
      {/* ======= Lasts Posts ======= */}
      <section id="blog">
        <LastestPost showSection="lastpost-2" />
      </section>
      {/* ======= Core Team - Grid Carrousel 5x4 ======= */}
      < section className="core__team__container" id="core" >
        <h2 className="section__title">Core Team</h2>
        <div className="core__team__content">
          <div className="core__boxes">
            {/* Alberto Fernández */}
            <div className="core__main">
              <div className="core__box">
                <OptimizedImage
                  src={albertoFernandez}
                  alt="albertoFernandez"
                  className="core__image"
                />
              </div>
              <div className="core__details">
                <h2 className="title__core">
                  Alberto
                  <br />
                  Fernández
                </h2>
                <span className="span__core">CEO & Founder</span>
                <p className="core__links">
                  <a
                    href="https://www.linkedin.com/in/alberto-fernandez-peregrina/"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>
            {/* David Marquéz */}
            <div className="core__main">
              <div className="core__box">
                <OptimizedImage
                  src={davidMarquez}
                  alt="davidMarquez"
                  className="core__image"
                />
              </div>
              <div className="core__details">
                <h2 className="title__core">
                  David
                  <br />
                  Márquez
                </h2>
                <span className="span__core">Chief Operations Officer</span>
                <p className="core__links">
                  <a
                    href="https://www.linkedin.com/in/david-m%C3%A1rquez-798698191/"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>
            {/* Antonio García */}
            <div className="core__main">
              <div className="core__box">
                <OptimizedImage
                  src={antonioOrnelas}
                  alt="antonioOrnelas"
                  className="core__image"
                />
              </div>
              <div className="core__details">
                <h2 className="title__core">
                  Antonio
                  <br />
                  García
                </h2>
                <span className="span__core">Chief of Research and Development</span>
                <p className="core__links">
                  <a
                    href="https://www.linkedin.com/in/antonio-garc%C3%ADa-1b7504232/"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>
            {/* Ángel Arzate */}
            <div className="core__main">
              <div className="core__box">
                <OptimizedImage
                  src={angelArzate}
                  alt="angelArzate"
                  className="core__image"
                />
              </div>
              <div className="core__details">
                <h2 className="title__core">
                  Ángel
                  <br />
                  Arzate
                </h2>
                <span className="span__core">Chief Marketing Officer</span>
                <p className="core__links">
                  <a
                    href="https://www.linkedin.com/in/angel-arzate-moreno-4349a1ab/"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>
            {/* Juan Jose de la Rosa */}
            <div className="core__main">
              <div className="core__box">
                <OptimizedImage
                  src={JuanDeLaRosa}
                  alt="juanJose"
                  className="core__image"
                />
              </div>
              <div className="core__details">
                <h2 className="title__core">
                  Juan José
                  <br />
                  de la Rosa
                </h2>
                <span className="span__core">Chief Technology Officer</span>
                <p className="core__links">
                  <a
                    href="https://www.linkedin.com/in/juan-jose-de-la-rosa/"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>
            {/* Paola Garrido */}
            <div className="core__main">
              <div className="core__box">
                <OptimizedImage
                  src={PaolaGarrido}
                  alt="Paola Garrido"
                  className="core__image"
                />
              </div>
              <div className="core__details">
                <h2 className="title__core">
                  Paola
                  <br />
                  Garrido
                </h2>
                <span className="span__core">Head of Communications</span>
                <p className="core__links">
                  <a
                    href="https://www.linkedin.com/in/paolagarridobarrera/"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* ======= Join Eno - VIDEO ======= */}
      < section className="join__container" >
        <div className="blurry__sphere-right"></div>
        <div className="join__content">
          <div className="join__left">
            <span>JOIN TO THE</span>
            <h2 className="join__title">ENO COMMUNITY</h2>
            <p className="join__subtitle">
              The combination of our passions and Web3 technology, when supported by artificial intelligence, elevates all areas of our lives. This enhances ENO’s social media platforms, making them centers of engaging dialogues. Dive into this extraordinary journey and become part of our community!
            </p>
            <div className="global__icons">
              <div className="social__container">
                <div className="social__icons__container">
                  <div className="social__icons icon__list">
                    <a href="https://t.me/ENO_network" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a
                      href="https://discord.com/invite/3eZewHzJU5"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a
                      href="https://x.com/ENOnetwork"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>

                    <a href="https://zealy.io/cw/eno/questboard" rel="noopener noreferrer">
                      <OptimizedImage className="Zealy" src={Zealy} />
                    </a>

                    <a href="https://coinmarketcap.com/community/profile/EnoToken" rel="noopener noreferrer">
                      <OptimizedImage className="MarketIcon" src={coinMarketCap} />
                    </a>

                    <a href="https://www.coingecko.com/es/monedas/eno" rel="noopener noreferrer">
                      <OptimizedImage className="CoinGecko" src={coinGecko} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="join__right">
            <video autoPlay loop muted playsInline className="video__corcho">
              <source src={vortex} type="video/mp4" />
            </video>
          </div>
        </div>
      </section >
      {/* ===================== Footer ===================== */}
      < div className="footer__content" >
        <div className="footer__left">
          <div>
            <center className="centerLogo__image">
              <OptimizedImage
                alt="Enologo"
                src={enologo}
                effect="blur"
                className="footer__logo"
              />
            </center>
            <p className="span__section" id="span__footer">
              One Network, Infinite Possibilities
            </p>
            {/* Iconos Sociales */}
            <div className="global__icons">
              <div className="social__container">
                <div className="social__icons__container">
                  <div className="social__icons icon__list">
                    <a
                      href="https://t.me/ENO_network"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a
                      href="https://discord.com/invite/3eZewHzJU5"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a
                      href="https://x.com/ENOnetwork"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/enonetwork"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a
                      href="https://www.youtube.com/@eno.network"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a
                      href="https://www.instagram.com/enonetwork/"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a
                      href="https://www.facebook.com/ENOtoken"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://warpcast.com/enotoken" rel="noopener noreferrer">
                      <OptimizedImage className="warpIcon" src={warpCast} />
                    </a>

                    <a href="https://coinmarketcap.com/community/profile/EnoToken" rel="noopener noreferrer">
                      <OptimizedImage className="MarketIcon" src={coinMarketCap} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__right">
          <h2 className="span__section">Join Our Newsletter</h2>
          <form
            action="https://enowinesocialclub.activehosted.com/proc.php"
            method="POST"
            novalidate
            className="form__contact-form"
          >
            <input type="hidden" name="u" value="21" />
            <input type="hidden" name="f" value="21" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <input
              type="hidden"
              name="or"
              value="7522c8bdac19880a4c759091b50d5448"
              className="input__contact-form"
            />
            <div className="data__contact-form">
              <input
                type="text"
                id="email"
                name="email"
                required
                className="inputemail"
                placeholder="email@example.com"
              />
              <button type="submit" className="submit__contact-form">
                Subscribe
              </button>
            </div>
          </form>

          <div className="social__links">
            <div className="social__footer">
              <div className="socials">
                <p className="footer__link">Support</p>
                <a
                  className="name__link"
                  href="https://discord.com/invite/3eZewHzJU5"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </div>
              <div className="socials">
                <p className="footer__link">Learn</p>
                <a
                  className="name__link"
                  href="https://docs.enotoken.io/"
                  rel="noreferrer"
                >
                  Whitepaper
                </a>
              </div>
              <div className="socials">
                <p className="footer__link">News</p>
                <a
                  className="name__link"
                  href="https://blog.eno.network"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* ===================== Sub Footer ===================== */}
      < div className="subfooter__content" >
        <div className="footer__container">
          <div className="footer__box">
            <a
              href="https://docs.enotoken.io/terms-and-conditions"
              rel="noreferrer"
            >
              Terms of use
            </a>
          </div>
          <div className="footer__box">
            <a href="https://docs.enotoken.io/privacy-policy" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
          <div className="footer__box">
            <a href="https://docs.enotoken.io/cookie-policy" rel="noreferrer">
              Cookie Policy
            </a>
          </div>
        </div>
      </div >
      {/* ===================== All rights Reserved ===================== */}
      < div className="tokenRights" >
        <h2>© All rights Reserved 2024</h2>
      </div >
      <BackToTopButton />
    </div >
  );
};
export default App;
